<template>
  <div class="modal" :class="popupClass">
    <div v-on:click="hide" class="modal__background"></div>
    <div class="modal__modal">
      <div v-if="header" class="modal__title" v-html="header"></div>

      <div class="modal__body">
        <slot name="default">
          {{ body }}
        </slot>
      </div>
      <div class="modal__buttons" v-if="buttons">
        <button
          v-for="(button, index) in buttons"
          :key="index"
          :class="'modal__button ' + button.class"
          v-html="button.text"
          v-on:click="returnClick(button)"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  methods: {
    returnClick: function (button) {
      button.onClick(this);
    },
    hide: function () {
      this.popupClass = null;
    },
    show: function () {
      this.popupClass = "show";
    },
  },
  props: {
    header: null,
    body: null,
    buttons: null,
  },
  data: function () {
    return {
      popupClass: null,
    };
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";

@keyframes modal__background-fade {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

@-webkit-keyframes modal__slide-fwd-center {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0, 0);
    transform: translate(-50%, -50%) scale(0, 0);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1, 1);
    transform: translate(-50%, -50%) scale(1, 1);
  }
}
@keyframes modal__slide-fwd-center {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0, 0);
    transform: translate(-50%, -50%) scale(0, 0);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1, 1);
    transform: translate(-50%, -50%) scale(1, 1);
  }
}

.modal {
  display: none;
  &.show {
    display: block;

    .modal__background {
      animation-name: modal__background-fade;
      animation-duration: 0.2s;
    }

    .modal__modal {
      -webkit-animation: modal__slide-fwd-center 0.25s
        cubic-bezier(0.25, 0.46, 0.25, 0.94) both;
      animation: modal__slide-fwd-center 0.25s
        cubic-bezier(0.25, 0.46, 0.25, 0.94) both;
    }
  }
}

.modal__background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 5;
}

.modal__modal {
  position: fixed;
  max-height: 90vh;
  width: 90vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1, 1);
  background: #ffffff;
  text-align: left;
  z-index: 6;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;

  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);

  .modal__title {
    padding: 10px;
    text-align: center;
    background-color: $primary;
    line-height: 1.1;
    font-size: 1.15rem;
    text-transform: uppercase;
    //border-bottom:1px solid $primary;
  }

  .modal__body {
    padding: 20px;
  }

  .modal__buttons {
    border-top: 1px solid #dddddd;
    display: flex;

    .modal__button {
      flex-grow: 1;
      padding: 10px;
      border: none;
      border-right: 1px solid #dddddd;
      background: #ffffff;

      &.btn-default {
        background: #dddddd;
      }

      &.btn-danger {
        background: #f93440;
        color: #ffffff;
      }

      &.btn-primary {
        background: $primary;
        color: $body-color;
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}
</style>
