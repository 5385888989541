<template>
  <div class="unlimitedtime">
    <input
      ref="input_field"
      type="text"
      class="form-control"
      v-model="time"
      :disabled="disabled"
    />
  </div>
</template>
<script>
import Picker from 'pickerjs';

export default {
  name: "UnlimitedTime",
  mounted: function () {
    var self = this;
    var input = this.$refs.input_field;

    if (input) {
      input.onchange = function () {
        self.time = this.value;
      };
    }

    this.picker = new Picker(input, {
      format: "HH:mm",
      text: {
        title: this.header ? this.header : "Tijdstip",
        cancel: "Annuleer",
        confirm: "Selecteer",
      },
      max_hours: this.max_hours ? this.max_hours : 23,
      min_hours: this.min_hours ? this.min_hours : 0,
      max_minutes: this.max_minutes ? this.max_minutes : 59,
      min_minutes: this.min_minutes ? this.min_minutes : 0,
      raw_value: this.raw_value ? this.raw_value : false,
      increment:{
          minute: this.accuracy ? this.accuracy : 1,
      },
    });

  },
  data: function () {
    return {
      picker: null,
    };
  },
  props: [
    "value",
    "disabled",
    "header",
    "max_hours",
    "min_hours",
    "max_minutes",
    "min_minutes",
    "raw_value",
    "accuracy",
  ],
  watch: {
    value: function () {
      if (this.picker) {
        this.picker.setDate(this.time);
      }
    },
  },
  computed: {
    time: {
      get: function () {
        let response = ["00", "00"];
        if (this.value) {
          response = this.value.split(":");
        }
        return response[0] + ":" + response[1];
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";

.unlimitedtime {
  max-width: 100%;
  display: flex;
  input,
  span {
    float: left;
  }
  input {
    width: calc(50% - 5px);
    text-align: right;
    font-weight: bold;
    align-items: center;
    margin: 0;
    min-width: 50px;
    width: 100%;
    &:last-child {
      text-align: left;
    }
  }
  span {
    width: 10px;
    display: block;
    line-height: 50px;
    font-weight: bold;
    text-align: center;
  }
  &:after {
    clear: both;
    display: block;
    content: "";
  }
}
</style>
