<template>
  <div class="timeregistration">
    <div class="accordion-item__header">
      <div
        class="timeregistration__status"
        :class="getStatusClass(value.saved)"
      >
        &nbsp;
      </div>
      <div class="title" v-on:click="isCollapsed = !isCollapsed">
        {{ $t("Registration") }}
        {{ $moment(this.value.start, "HH:mm:ss").format("HH:mm") }} -
        {{ $moment(this.value.end, "HH:mm:ss").format("HH:mm")
        }}<span
          v-if="!value.valid || value.overlap || !badging_valid()"
          class="badge badge-warning"
          >!</span
        >
      </div>
      <button
        v-if="editable"
        v-on:click="showRemoveModal"
        class="timeregistration__remove"
      >
        <img src="/img/icon-close.png" alt="" />
      </button>
    </div>
    <div v-if="!isCollapsed">
      <div
        v-if="!badging_valid()"
        class="timeregistration-error alert alert-warning"
      >
        {{ $t("This timetracking is not within the badge times!") }}
      </div>
      <div
        v-if="value.overlap"
        class="timeregistration-error alert alert-warning"
      >
        {{ $t("This timetracking overlaps with another timetracking!") }}
      </div>
      <article class="accordion-item">
        <button class="accordion-item__header">
          <div class="img">
            <img src="/img/icon-clock.png" alt="" />
          </div>
          <div class="title">{{ $t("Timeregistration") }}</div>
        </button>
        <div class="accordion-item__body">
          <div v-if="!value.time_valid" class="alert alert-warning mb-2">
            {{ $t("End time needs to be bigger than start time!") }}
          </div>
          <table>
            <tr>
              <td>
                <div class="form-group">
                  <label for="">{{ $t("Start time") }}</label>
                  <UnlimitedTime
                    v-on:input="startChanged()"
                    :accuracy="trackingAccuracy"
                    v-model="value.start"
                    :header="$t('Start time')"
                    :disabled="!editable"
                  ></UnlimitedTime>
                </div>
              </td>
              <td>
                <div class="form-group">
                  <label for="">{{ $t("End time") }}</label>
                  <UnlimitedTime
                    :accuracy="trackingAccuracy"
                    v-model="value.end"
                    :header="$t('End time')"
                    :disabled="!editable"
                  ></UnlimitedTime>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="form-group">
                  <label for="">{{ $t("Total break time") }}</label>
                  <UnlimitedTime
                    :accuracy="trackingAccuracy"
                    v-on:input="validatePause"
                    v-model="value.pause"
                    :header="$t('Total break time')"
                    :disabled="!editable"
                    :class="{ 'is-invalid': errors.pause }"
                  ></UnlimitedTime>
                  <small
                    class="text-danger mb-2 d-inline-block"
                    v-if="errors.pause"
                  >
                    {{ errors.pause }}
                  </small>
                </div>
              </td>
              <td>
                <div class="form-group">
                  <label for="">{{ $t("Achieved") }}</label>
                  <input
                    class="form-control"
                    type="text"
                    :value="timeDifference"
                    disabled="disabled"
                    readonly="readonly"
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>
      </article>
      <article class="accordion-item" v-if="value.checklist">
        <button class="accordion-item__header">
          <div class="img">
            <img src="/img/icon-check.png" alt="" />
          </div>
          <div class="title">{{ $t("Checklist") }}</div>
        </button>
        <div class="accordion-item__body">
          <div class="mb-2">
            <strong>{{ value.checklist.title }}</strong>
            <div v-if="value.checklist.description != ''">
              {{ value.checklist.description }}
            </div>
          </div>

          <div
            v-for="question in value.checklist.items"
            :key="question.id"
            class="question"
          >
            <div v-if="question.type == 'one answer'">
              <div class="question__answers">
                <span class="question__answers__title">{{
                  question.question
                }}</span>
                <div
                  v-for="answer in question.answers"
                  :key="answer.id"
                  class="question__answers__answer"
                >
                  <input
                    class="prixo-checkbox"
                    :id="'question_' + question.id + '_answer_' + answer.id"
                    type="radio"
                    :name="'question_' + question.id"
                    :value="answer.id"
                    v-model="question.chosen_answers"
                  />
                  <label
                    :for="'question_' + question.id + '_answer_' + answer.id"
                    >{{ answer.answer }}</label
                  >
                </div>
              </div>
            </div>
            <div v-if="question.type == 'multiple answers'">
              <div class="question__answers">
                <span class="question__answers__title">{{
                  question.question
                }}</span>
                <div
                  v-for="answer in question.answers"
                  :key="answer.id"
                  class="question__answers__answer"
                >
                  <input
                    class="prixo-checkbox"
                    :id="'question_' + question.id + '_answer_' + answer.id"
                    type="checkbox"
                    :value="answer.id"
                    v-model="question.chosen_answers"
                  />
                  <label
                    :for="'question_' + question.id + '_answer_' + answer.id"
                    >{{ answer.answer }}</label
                  >
                </div>
              </div>
            </div>
            <div v-if="question.type == 'check'">
              <div class="question__answers">
                <div class="question__answers__answer">
                  <input
                    class="prixo-checkbox"
                    :id="'question_' + question.id"
                    type="checkbox"
                    :value="true"
                    v-model="question.chosen_answers"
                  />
                  <label :for="'question_' + question.id">{{
                    question.question
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article
        class="accordion-item"
        v-if="
          equipment.length || (value.equipment && value.equipment.length > 0)
        "
      >
        <button class="accordion-item__header">
          <div class="img">
            <img src="/img/icon-cog.png" alt="" />
          </div>

          <div class="title">{{ $t("Use equipments") }}</div>
        </button>

        <div class="accordion-item__body">
          <div
            v-if="value.equipment && value.equipment.length > 0"
            class="machine-list"
          >
            <div
              class="machine-list-item mb-2"
              v-for="(equipment, index) in value.equipment"
              :key="index"
            >
              <div class="machine-list-item-title">
                {{ equipment.title }}
              </div>
              <div class="d-flex align-items-center">
                <div
                  class="machine-list-item-value machine-list-item-body input-group"
                  :class="{ error: errors.equipments[index] }"
                >
                  <label v-if="equipment.unit == 'time'" class="unit-label">{{
                    $t("time")
                  }}</label>
                  <label v-if="equipment.unit == 'distance'" class="unit-label"
                    >Km</label
                  >
                  <label v-if="equipment.unit == 'days'" class="unit-label">{{
                    $t("days")
                  }}</label>
                  <UnlimitedTime
                    v-if="equipment.unit == 'time'"
                    :accuracy="trackingAccuracy ? trackingAccuracy : 1"
                    :value="
                      equipment.unit == 'time'
                        ? getMinutesAsTime(equipment.amount)
                        : equipment.amount
                    "
                    @input="
                      (value) => {
                        setEquipmentAmount(value, index);
                      }
                    "
                    :disabled="!editable"
                    :max_hours="300"
                    :raw_value="true"
                  ></UnlimitedTime>
                  <div>
                    <input
                      type="number"
                      v-if="equipment.unit != 'time'"
                      v-model="value.equipment[index].amount"
                      :disabled="!editable"
                      min="0"
                      class="form-control machine-list-item-number"
                      @blur="validateEquipment"
                    />
                    <div>
                      <small
                        class="text-danger mb-2 d-block"
                        v-if="errors.equipments[index]"
                      >
                        {{ errors.equipments[index] }}
                      </small>
                    </div>
                  </div>
                </div>

                <div class="machine-list-item-button">
                  <button
                    v-on:click="removeEquipment(index)"
                    v-if="editable"
                    class="btn-remove"
                  ></button>
                </div>
              </div>
            </div>
          </div>

          <button
            v-if="editable"
            class="btn-add"
            v-on:click="showEquipmentPopup"
          >
            {{ $t("Add equipment") }}
          </button>
        </div>
      </article>
      <article
        class="accordion-item"
        v-if="articlesList && articlesList.length > 0"
      >
        <button class="accordion-item__header">
          <div class="img">
            <img src="/img/icon-cog.png" alt="" />
          </div>

          <div class="title">{{ $t("Use articles") }}</div>
        </button>

        <div class="accordion-item__body">
          <div
            v-if="value.articles && value.articles.length > 0"
            class="article-list"
          >
            <div
              class="article-list-item mb-2"
              v-for="(article, index) in value.articles"
              :key="index"
            >
              <div class="article-list-item-title">
                <label>{{ article.name }}</label>
              </div>
              <div class="d-flex align-items-center">
                <div
                  class="d-flex flex-grow-1 input-group article-list-item-body"
                  :class="{ error: errors.articles[index] }"
                >
                  <input
                    type="number"
                    v-model="value.articles[index].amount"
                    :disabled="!editable"
                    min="0"
                    placeholder="Aantal"
                    class="form-control article-list-item-number w-100 mb-0"
                    @blur="validate"
                  />
                  <div class="form-control--append">
                    <select
                      :disabled="!editable"
                      v-if="article.units"
                      class="form-control mb-0"
                      v-model="article.unit"
                      placeholder="Eenheid"
                    >
                      <option
                        v-for="unit in article.units"
                        :key="unit"
                        :value="unit"
                      >
                        {{ unit }}
                      </option>
                    </select>
                  </div>
                </div>
                <button
                  v-on:click="removeArticle(index)"
                  v-if="editable"
                  class="btn-remove"
                ></button>
              </div>
              <small
                class="text-danger mb-2 d-inline-block"
                v-if="errors.articles[index]"
                >{{ errors.articles[index] }}</small
              >
            </div>
          </div>

          <button
            v-if="editable"
            class="btn-add"
            v-on:click="showArticlesPopup"
          >
            {{ $t("Add article") }}
          </button>
        </div>
      </article>
      <article class="accordion-item">
        <button class="accordion-item__header">
          <div class="img">
            <img src="/img/icon-text.png" alt="" />
          </div>
          <div class="title">{{ $t("Anything to report?") }}</div>
        </button>
        <div class="accordion-item__body">
          <div class="from-group">
            <textarea
              v-model="value.remark"
              cols="30"
              rows="10"
              class="form-control"
              :disabled="!editable"
            ></textarea>
          </div>
        </div>
      </article>
      <article
        class="accordion-item"
        v-if="settings.can_set_task_as_finished > 0"
      >
        <button class="accordion-item__header">
          <div class="img">
            <img src="/img/icon-check.png" alt="" />
          </div>
          <div class="title">{{ $t("Finished?") }}</div>
        </button>
        <div class="accordion-item__body">
          <ListItem
            v-model="value.task_is_finished"
            :key="checkFinished.label"
            :layout="checkFinished.layout"
            :label="checkFinished.label"
            :config="checkFinished.config"
            :icon="checkFinished.icon"
            :type="checkFinished.type"
          ></ListItem>
        </div>
      </article>
    </div>
    <SelectPopup
      v-on:itemselected="addEquipment"
      ref="equipmentPopup"
      :header="$t('Add equipment')"
      :listsearch="true"
      :items="equipment"
    ></SelectPopup>
    <SelectPopup
      v-on:itemselected="addArticle"
      ref="articlesPopup"
      :header="$t('Add article')"
      :listsearch="true"
      :items="articlesList"
      value-text="name"
    ></SelectPopup>

    <Modal
      ref="removeModal"
      :header="$t('Remove registration')"
      :buttons="buttons"
      :body="$t('Are you sure you want to remove this registration?')"
    ></Modal>
  </div>
</template>

<script>
import SelectPopup from "./../components/SelectPopup.vue";
import UnlimitedTime from "./../components/UnlimitedTime.vue";
import ListItem from "./../components/ListItem.vue";
import Modal from "./../components/Modal.vue";

export default {
  components: {
    ListItem,
    SelectPopup,
    UnlimitedTime,
    Modal,
  },
  name: "TimeRegistration",
  props: {
    trackingAccuracy: null,
    value: null,
    equipment: null,
    collapsed: null,
    timeslot: Object,
    settings: {
      can_set_task_as_finished: null,
      can_only_track_within_badge_times: null,
    },
  },
  data: function () {
    return {
      buttons: [
        {
          class: "",
          text: this.$t("Cancel"),
          onClick: function (el) {
            el.hide();
          },
        },
        {
          class: "btn-danger",
          text: this.$t("Delete"),
          onClick: this.removeMe,
        },
      ],
      articlesList: null,
      isCollapsed: true,
      checkFinished: {
        label: "Taak is afgewerkt",
        type: "switch",
        config: {
          theme: "prixo",
          color: "primary",
          type_bold: true,
        },
        value: false,
        layout: "inline",
      },
      errors: {
        articles: [],
        equipments: [],
      },
    };
  },
  created() {
    if (this.timeslot.task.checklist) {
      if (this.timeslot.task.checklist.items) {
        this.value.checklist = this.timeslot.task.checklist;
        for (let i = 0; i < this.value.checklist.items.length; i++) {
          if (!this.value.checklist.items[i].chosen_answers) {
            if (this.value.checklist.items[i].type == "multiple answers") {
              this.value.checklist.items[i].chosen_answers = [];
            }
          }
        }
      }
    }

    if (this.value.articles_used) {
      this.value.articles = [];

      for (let i = 0; i < this.value.articles_used.length; i++) {
        const used_article = this.value.articles_used[i];

        let article = {};
        if (used_article.article) {
          article = used_article.article;
        } else {
          article.name = used_article.name;
          article.id = used_article.article_id;
          article.call_name = used_article.call_name;
          article.category = { name: used_article.category_name };
          article.brand = { name: used_article.brand_name };
          article.code = used_article.code;
        }

        if (article.unit && JSON.parse(article.unit)) {
          article.units = JSON.parse(article.unit);
        } else {
          article.units = [used_article.unit];
        }

        article.unit = used_article.unit;
        article.amount = used_article.amount;

        this.value.articles.push(article);
      }
    }

    this.value.equipment = [];

    if (
      this.value.equipment_id !== null &&
      typeof this.value.equipment_id != "undefined"
    ) {
      this.$database
        .getDataById("equipment", this.value.equipment_id)
        .then((data) => {
          data.amount = this.value.equipment_amount;
          this.value.equipment.push(data);
        });
    }

    if (this.value.equipment_used) {
      for (let i = 0; i < this.value.equipment_used.length; i++) {
        const used_equipment = this.value.equipment_used[i];

        let equipment = {};
        if (used_equipment.equipment) {
          equipment = used_equipment.equipment;
        } else {
          equipment.title = used_equipment.title;
          equipment.description = used_equipment.description;
          equipment.id = used_equipment.equipment_id;
          equipment.internal_number = used_equipment.internal_number;
          equipment.category = { name: used_equipment.category_name };
        }

        equipment.unit = used_equipment.unit;
        equipment.amount = used_equipment.amount;

        this.value.equipment.push(equipment);
      }
    }
  },
  mounted: function () {
    this.isCollapsed =
      this.collapsed == null || this.collapsed == false ? false : true;

    // Getting available articles
    this.$database.readAllData("articles").then((data) => {
      this.articlesList = data;
    });

    //Aanroepen om berekening van 'valid' door te voeren
    this.timeDifference;
  },
  computed: {
    timeDifference: function () {
      let end = this.$moment(this.value.end, "HH:mm:ss");
      let start = this.$moment(this.value.start, "HH:mm:ss");
      var duration = this.$moment
        .duration(end.diff(start))
        .subtract(this.value.pause);
      if (start < end) {
        // eslint-disable-next-line
        this.value.time_valid = true;
        // eslint-disable-next-line
        this.value.valid = true;
      } else {
        // eslint-disable-next-line
        this.value.time_valid = false;
        // eslint-disable-next-line
        this.value.valid = false;
      }
      this.$forceUpdate();
      this.$emit("update", this);
      return this.$moment.utc(duration.asMilliseconds()).format("HH:mm");
    },
    editable: function () {
      if (!(this.value && this.value.accepted == 1)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    getMinutesAsTime(amount) {
      var duration = this.$moment.duration(amount, "minutes");
      return (
        this.$helper.pad(Math.floor(duration.asHours()), 2) +
        ":" +
        this.$helper.pad(duration.minutes(), 2)
      );
    },
    validate() {
      let valid = true;

      if (this.value.articles) {
        for (let i = 0; i < this.value.articles.length; i++) {
          const article = this.value.articles[i];
          if (article.amount && article.amount != "") {
            this.errors.articles[i] = false;
          } else {
            this.errors.articles[i] = "Geef een geldig aantal in!";
            valid = false;
          }
        }
      }

      this.$emit("validated", valid);

      this.$forceUpdate();

      return valid;
    },
    validateEquipment() {
      let valid = true;

      if (this.value.equipment) {
        for (let i = 0; i < this.value.equipment.length; i++) {
          const equipment = this.value.equipment[i];
          if (equipment.amount && equipment.amount != "") {
            this.errors.equipments[i] = false;
          } else {
            this.errors.equipments[i] = "Geef een geldig aantal in!";
            valid = false;
          }
        }
      }

      this.$emit("validated", valid);

      this.$forceUpdate();

      return valid;
    },
    validatePause() {
      let valid = true;
      let end = this.$moment(this.value.end, "HH:mm:ss");
      let start = this.$moment(this.value.start, "HH:mm:ss");
      var duration = this.$moment
        .duration(end.diff(start))
        .subtract(this.value.pause)
        .asMilliseconds();
      if (duration <= 0) {
        this.errors.pause =
          "Pauze tijd mag niet langer zijn dan totale werktijd";
        valid = false;
      } else {
        this.errors.pause = false;
      }
      this.$emit("validated", valid);

      this.$forceUpdate();

      return valid;
    },
    addArticle: function (item) {
      this.$refs.articlesPopup.hide();

      let article = { ...item };
      article.units = [];
      article.amount = 1;

      if (article.unit && JSON.parse(article.unit)) {
        article.units = JSON.parse(article.unit);
        article.unit = "";

        if (article.units[0]) {
          article.unit = article.units[0];
        }
      }

      if (!this.value.articles) {
        this.value.articles = [];
      }

      this.value.articles.push(article);
      this.$forceUpdate();
    },
    removeArticle: function (index) {
      this.value.articles.splice(index, 1);
      this.$forceUpdate();
    },
    removeMe: function (el) {
      el.hide();
      this.$emit("delete", this);
    },
    showRemoveModal: function () {
      this.$refs.removeModal.show();
    },
    showEquipmentPopup: function () {
      this.$refs.equipmentPopup.show();
    },
    showArticlesPopup: function () {
      this.$refs.articlesPopup.show();
    },
    addEquipment: function (item) {
      this.$refs.equipmentPopup.hide();

      let equipment = { ...item };
      equipment.amount = 0;

      if (!this.value.equipment) {
        this.value.equipment = [];
      }

      this.value.equipment.push(equipment);
      this.$forceUpdate();
    },
    removeEquipment: function (index) {
      this.value.equipment.splice(index, 1);
      this.$forceUpdate();
    },
    startChanged: function () {
      let end = this.$moment(this.value.end, "HH:mm:ss");
      let start = this.$moment(this.value.start, "HH:mm:ss");
      let pause = this.$moment.duration(this.value.pause);
      var duration = this.$moment
        .duration(end.diff(start))
        .subtract(this.value.pause)
        .asMilliseconds();
      if (duration <= 0) {
        this.value.end = start
          .add(pause.asMinutes() + 15, "minutes")
          .format("HH:mm:ss");
      }
    },
    badging_valid: function () {
      if (
        this.value.badge_in != null &&
        this.$moment(this.value.badge_in.time, "HH:mm") >
          this.$moment(this.value.start, "HH:mm:ss")
      ) {
        if (this.settings.can_only_track_within_badge_times > 0) {
          this.value.valid = false;
        }

        this.$emit("update", this);
        return false;
      }

      if (
        this.value.badge_out != null &&
        this.$moment(this.value.badge_out.time, "HH:mm") <
          this.$moment(this.value.end, "HH:mm:ss")
      ) {
        if (this.settings.can_only_track_within_badge_times > 0) {
          this.value.valid = false;
        }
        this.$emit("update", this);
        return false;
      }

      return true;
    },
    refresh: function () {
      this.$forceUpdate();
    },
    getStatusClass(saved) {
      return {
        "timeregistration__status--danger": saved == false,
        "timeregistration__status--success": saved != false,
      };
    },
    setEquipmentAmount(value, index) {
      this.value.equipment[index].amount = this.$moment
        .duration(value)
        .asMinutes();
    },
  },
};
</script>
